var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("main", { staticClass: "single" }, [
    _c("div", { staticClass: "background" }, [
      _c("section", { staticClass: "form" }, [
        _c("h2", [_vm._v(_vm._s(_vm.label))]),
        _vm._v(" "),
        _c(
          "form",
          {
            on: {
              submit: function ($event) {
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.handleSubmit.apply(null, arguments)
              },
            },
          },
          [
            _c("InputGroup", { ref: "form", attrs: { config: _vm.config } }),
            _vm._v(" "),
            _c("FooterField", {
              attrs: { submit: { label: _vm.i18n.t("Reset") }, cancel: false },
              on: { submit: _vm.handleSubmit },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }