<template>
	<main class="single">
		<div class="background">
			<section class="form">
				<h2>{{ label }}</h2>
				<form @submit.stop.prevent="handleSubmit">
					<InputGroup
						ref="form"
						:config="config"
					/>
					<FooterField
						:submit="{label: i18n.t('Reset')}"
						:cancel="false"
						@submit="handleSubmit"
					/>
				</form>
			</section>
		</div>
	</main>
</template>

<script>
/**
 * @class PasswordNewReset
 *
 * User can set a new password with a valid token and username.
 *
 * Two scenarios:
 * 1. User needs to set a new password for the first time
 * 2. User forgot their existing password
 * */
import InputGroup from 'tucows-ui-components/src/Input/InputGroup.vue';
import FooterField from 'tucows-ui-components/src/FooterField.vue';
import {mapActions, mapState} from 'vuex';
import i18n from '@/lib/i18n';
import _get from 'lodash/get';

export default {
	components: {
		FooterField,
		InputGroup
	},
	props: {
		firstTimeUser: {
			type: Boolean,
			/**
			 * If user is changing the password for the first time
			 * @return {boolean} first time user
			 */
			default() {
				return false;
			}
		}
	},
	/**
	 * @ignore
	 */
	data() {
		return {
			config: [
				{
					name: 'password',
					compType: 'password',
					required: true,
					label: i18n.t('Password'),
					value: '',
					validations: [
						{
							id: 1,
							regex: /[0-9]/,
							message: this.i18n.t('Must have at least one number'),
							status: false
						},
						{
							id: 2,
							regex: /^(?=.*[A-Z])(?=.*[a-z]).*$/,
							message: this.i18n.t('Must have lower and upper case letter(s)'),
							status: false
						},
						{
							id: 3,
							regex: /^(?=.*[@$-/:-?{-~!"^_'[\]]).*$/,
							message: this.i18n.t('Must have a special character'),
							status: false
						},
						{
							id: 4,
							regex: /^.{14,}$/,
							message: this.i18n.t('Must be 14 or more characters'),
							status: false
						}
					]
				}
			]
		};
	},
	computed: {
		...mapState({errors: (state) => _get(state, 'user.errors')}),
		/**
		 * Get token
		 * @return {string} token
		 */
		token() {
			return (
				_get(this.$route, 'params.token') || _get(this.$route, 'query.token')
			);
		},
		/**
		 * Get username
		 * @return {string} username
		 */
		username() {
			const email =
				_get(this.$route, 'params.username', '') ||
				_get(this.$route, 'query.username', '');
			return email.replace(' ', '+');
		},
		/**
		 * If first time user can set your password, else reset your password
		 *
		 */
		label() {
			return this.firstTimeUser
				? i18n.t('Set Your Password')
				: i18n.t('Reset Your Password');
		}
	},
	/**
	 * Make sure user is logged out before setting new password
	 */
	created() {
		this.logout();
	},
	methods: {
		...mapActions({
			showSuccess: 'common/showSuccess',
			resetPassword: 'user/resetPassword',
			logout: 'user/logout'
		}),
		/**
		 * Setting the password
		 */
		handleSubmit() {
			if (this.$refs.form.isValid()) {
				const data = this.$refs.form.getData();
				this.resetPassword({
					username: this.username,
					token: this.token,
					password: _get(data, 'password')
				}).then(() => {
					if (!this.errors) {
						this.showSuccess();
						this.$router.push('/login');
					}
				});
			}
		}
	}
};
</script>
